<template>
  <div>
    <div class="d-flex flex-wrap align-items-center">
      <h4 class="mb-0 mr-auto">
        {{ $t('store_list_page.store_list') }}
      </h4>
    </div>
    <div v-if="true" class="row no-gutters my-3">
      <div class="col-md-4 d-flex align-items-center pr-md-4">
        Channel:
        <a-select
          class="flex-grow-1 ml-3 text-capitalize select-antd-default"
          size="large"
          :value="valueChannel"
          @change="onChangeChannel"
        >
          <a-select-option
            v-for="state in list_channel"
            :key="state.sales_channel_id"
            class="text-capitalize"
            :value="state.sales_channel_id"
          >
            {{ state.name }}
          </a-select-option>
        </a-select>
      </div>
      <div class="col-md-4 d-flex align-items-center">
        {{ $t('store_list_page.store_status') }}:
        <a-select
          class="flex-grow-1 ml-3 text-capitalize select-antd-default"
          size="large"
          :value="valueStatus"
          @change="onChangeStatus"
        >
          <a-select-option
            v-for="state in list_status"
            :key="state"
            class="text-capitalize"
            :value="state"
          >
            {{ state }}
          </a-select-option>
        </a-select>
      </div>
      <div class="col-md-4 d-flex align-items-center pl-md-4">
        {{ $t('store_list_page.country') }}:
        <a-select
          class="flex-grow-1 ml-3 select-antd-default"
          size="large"
          :value="valueCountry"
          @change="onChangeCountry"
        >
          <a-select-option
            v-for="country in COUNTRIES"
            :key="country.code"
            :value="country.code"
          >
            {{ country.name }}
          </a-select-option>
        </a-select>
      </div>
    </div>
    <div v-if="true" class="row mx-0">
      <div class="col-md-9 pl-0">
        <a-input-search
          :loading="loadingSearch"
          size="large"
          :placeholder="$t('store_list_page.search_placeholder')"
          :value="valueSearch"
          @change="onChangeSearch"
          @search="onSearch"
        >
          <a-button slot="enterButton" class="btn-search">
            {{ $t('utils.search') }} <a-icon type="search" />
          </a-button>
        </a-input-search>
      </div>
      <div class="col-md-3 pr-0 d-flex align-items-center">
        <div class="flexno pr-3">
          {{ `${$t('order.sortBy')}:` }}
        </div>
        <a-select
          class="w-100 ml-auto text-capitalize select-antd-default"
          size="large"
          :value="sortBy"
          option-filter-prop="children"
          @change="onChangeSort"
        >
          <a-select-option
            v-for="sort in sort_"
            :key="sort.value"
            class="text-capitalize"
            :value="sort.value"
          >
            {{ sort.label }}
          </a-select-option>
        </a-select>
      </div>
    </div>

    <TableBorder class="mt-3">
      <template #thead>
        <th v-for="th in THEAD" :key="th" scope="col">
          {{ $t('store_list_page.' + th) }}
        </th>
      </template>
      <tbody v-if="data?.length">
        <tr v-for="val in data" :key="val.id">
          <td>
            <div class="store-name" @click="onShowDrawerDetail(val)">
              <img
                :alt="val.title"
                draggable="false"
                width="30"
                height="30"
                class="mr-2"
                :src="
                  val.sales_channel.icon_url
                    ? val.sales_channel.icon_url
                    : 'https://www.shareicon.net/data/2016/06/30/788775_food_512x512.png'
                "
              />
              {{ val.title }}
            </div>
          </td>
          <td
            v-if="val.status"
            :class="val.status ? 'st-connect' : 'st-disconnected'"
          >
            {{
              val.status === 'CONNECTED'
                ? 'Terhubung'
                : val.status === 'DISCONNECTED'
                  ? 'Terputus'
                  : val.status === 'WAITING'
                    ? 'Menunggu'
                    : val.status === 'PENDING_WAREHOUSE'
                      ? 'Pending Warehouse'
                      : val.status
            }}
          </td>
          <td v-else>
            {{ '-' }}
          </td>
          <td>
            <time>{{
              val.connected_at
                ? $moment(val.connected_at, 'YYYY-MM-DD HH:mm').format(
                  'DD-MM-YYYY HH:mm',
                )
                : '-'
            }}</time>
            <!-- <time>02 Juni 2022 - 09:00 AM</time> -->
          </td>
          <td>
            <!-- <img
              :alt="val.sales_channel.country"
              draggable="false"
              class="user-select-none mr-2"
              :src="FLAGS[val.sales_channel.country]"
            > -->
            {{
              val.sales_channel.country_name
                ? val.sales_channel.country_name
                : '-'
            }}
          </td>
          <td>
            <!-- <span v-if="val.product_qty">{{ val.product_qty }} {{ $t('store_list_page.product') }}</span>
            <span v-else>-</span> -->
            <ConsoleLink
              v-if="!val.confirmed"
              :to="{
                name: 'store.product-synchronize',
                params: { id: val.id },
              }"
              class="text-primary font-weight-bold"
            >
              {{ val.notes ? val.notes : '-' }}
              <!-- {{ $t('store_list_page.unconfirmed') }} -->
              <!-- {{ $t(`store_list_page.${val.confirmed ? 'confirmed' : 'unconfirmed'}`) }} -->
            </ConsoleLink>
          </td>
          <td style="width: 130px">
            <a-button
              v-if="val?.sales_channel?.code.toLowerCase() === 'tokopedia_id' && val?.status?.toUpperCase() === 'WAITING'"
              type="success"
              icon="check"
              class="btn-url"
              @click.prevent="openModal(val)"
            />
            <a-button
              v-if="val?.sales_channel?.code.toLowerCase() === 'tokopedia_id' && val?.status?.toUpperCase() === 'PENDING_WAREHOUSE'"
              icon="shop"
              class="btn-url"
              @click.prevent="openModalAddWarehouse(val)"
            />
          </td>
        </tr>
      </tbody>
    </TableBorder>

    <LoadingListTable v-if="loadingData" />
    <template v-else>
      <ErrorInfo v-if="errorRows" :error-type="errorRows" />
      <EmptyInfo
        v-else-if="!errorRows && !data.length"
        :title="$t('store_list_page.empty_title')"
        :description="$t('store_list_page.empty_msg')"
      />
    </template>

    <Pagination
      v-if="!loadingData && data.length"
      class="text-right"
      :total="total_row"
      :page-size="pageSize"
      :total-length-per-page="data.length"
      :default-page="defaultPage"
      @changePage="onChangePage"
    />

    <modal-approve-confirmation
      :visible="modalApproveConfirmation"
      :open-modal="openModalApproveConfirmation"
      :selected-data="selectedData"
      @emitSuccessApprove="onSuccessApprove"
    />
    <modal-add-warehouse-channel
      :visible="showModalAddWarehouseChannel"
      :open-modal="actionModalAddWarehouseChannel"
      :selected-data="selectedData"
      @emitSuccessAddWarehouse="onSuccessAddWarehouse"
    />
    <a-drawer
      title="Detail Toko"
      placement="right"
      :closable="true"
      :visible="viewDetail"
      width="500"
      @close="viewDetail = false, dataDrawerDetail = null"
    >
      <div class="d-flex flex-column drawer-container">
        <div class="d-flex">
          <div class="w-50">
            <div class="font-weight-bold">
              Nama Toko
            </div>
            <div>{{ dataDrawerDetail?.title }}</div>
          </div>
          <div class="w-50">
            <div class="font-weight-bold">
              Last Product Sync
            </div>
            <div>-</div>
          </div>
        </div>
        <div class="d-flex">
          <div class="w-50">
            <div class="font-weight-bold">
              Status Toko
            </div>
            <div>{{ dataDrawerDetail?.status ?? '-' }}</div>
          </div>
          <div class="w-50">
            <div class="font-weight-bold">
              Last Order Sync
            </div>
            <div>-</div>
          </div>
        </div>
        <div class="d-flex">
          <div class="w-50">
            <div class="font-weight-bold">
              Warehouse
            </div>
            <div>-</div>
          </div>
          <div class="w-50">
            <div class="font-weight-bold">
              Auto confirm
            </div>
            <div>-</div>
          </div>
        </div>
        <div class="d-flex">
          <div class="w-100">
            <div class="font-weight-bold">
              API Key
            </div>
            <div>-</div>
          </div>
        </div>
        <div class="d-flex">
          <div class="w-100">
            <div class="font-weight-bold">
              Store URL
            </div>
            <div>-</div>
          </div>
        </div>
      </div>
    </a-drawer>
  </div>
</template>

<script>

import LoadingListTable from '@/components/Loading/ListTable'
import EmptyInfo from '@/components/EmptyInfo'
import ErrorInfo from '@/components/ErrorInfo'
import TableBorder from '@/components/Table/TableBorder.vue'
import Pagination from '@/components/Pagination/index.vue'
import ConsoleLink from '@/components/ConsoleLink/ConsoleLink.vue'
import ModalApproveConfirmation from '@/components/Store/AdminStore/ModalApproveConfirmation.vue'
import ModalAddWarehouseChannel from '@/components/Store/AdminStore/ModalAddWarehouseChannel.vue'

/* Add Marketplace & Warehouse */
// import addStore from '@/composable/store/addStore'
import getQueryParam from '@/utils/getQueryParam'

const SORT_BY = 'alphabetical'
export default {
  name: 'ProductConfirmation',
  components: {
    LoadingListTable,
    EmptyInfo,
    ErrorInfo,
    TableBorder,
    Pagination,
    ConsoleLink,
    ModalApproveConfirmation,
    ModalAddWarehouseChannel,
  },
  mixins: [getQueryParam],
  data() {
    return {
      // FLAGS: {
      //   228: FLAG_ID,
      //   229: FLAG_MY,
      // },
      COUNTRIES: [],
      THEAD: [
        'store_name',
        'store_status',
        'authorization_time',
        'country',
        'description',
        'action',
      ],
      loadingData: true,
      loadingSearch: false,
      valueSearch: '',
      valueChannel: 'all',
      valueStatus: 'Semua Status',
      valueCountry: 'all',
      sortBy: SORT_BY,
      total_row: 20,
      pageSize: 20,
      defaultPage: 1,
      data: [],
      errorRows: false,
      list_channel: [],
      list_status: [],
      showModalEdit: false,
      showSuccess: false,
      showFailed: false,
      rowData: {},
      sort_: [
        {
          label: 'Alfabetis',
          value: 'alphabetical',
        },
        {
          label: 'Numerik',
          value: 'numeric',
        },
      ],
      modalApproveConfirmation: false,
      selectedData: null,
      showModalAddWarehouseChannel: false,
      viewDetail: false,
      dataDrawerDetail: null,
    }
  },
  computed: {
    permission() {
      return this.$store.getters['user/can'](this.$route.meta.key)
    },
  },
  watch: {
    '$route.query'() {
      this.onRequest()
    },
    // permission(newValue) {
    //   if(!this.$route.query.business_id && !newValue.length) {
    //     this.$router.push({path: '/error/403', query: {...this.$route.query}})
    //   }
    // },
  },
  mounted() {
    this.onRequest()
  },
  methods: {
    onRequest() {
      const { page, status, country_id, sales_channel_id, keyword } = this.$route.query
      this.loadingData = true
      this.data = []
      this.defaultPage = +page || 1

      let query = {
        ...this.$route.query,
        page: this.defaultPage,
      }
      this.$store
        .dispatch('channel/GETCHANNEL', {
          ...query,
        })
        .then((res) => {
          this.data = res.data
          this.total_row = res.total_row

          this.COUNTRIES = res.country_data
            .filter((v) => {
              return v.id
            })
            .map((v) => {
              return {
                code: v.id,
                name: v.name,
              }
            })
          this.list_status = res.status_data.filter((v) => {
            return v
          })
          this.list_channel = res.sales_channel_data.map((v) => {
            return {
              sales_channel_id: v.id,
              name: v.name,
            }
          })

          this.list_channel.push({
            sales_channel_id: 'all',
            name: 'Semua Channel',
          })
          this.COUNTRIES.push({
            code: 'all',
            name: 'Semua Negara',
          })
          this.list_status.push('Semua Status')

          this.list_channel.unshift(this.list_channel.pop())
          this.list_status.unshift(this.list_status.pop())
          this.COUNTRIES.unshift(this.COUNTRIES.pop())

          this.valueSearch = keyword || ''
          this.valueChannel = Number(sales_channel_id) || 'all',
          this.valueStatus = status || 'Semua Status',
          this.valueCountry = Number(country_id) || 'all',
          this.loadingData = false
        })
        .catch((err) => {
          console.error('err: ', err)
          this.loadingData = false
        })
    },
    onChangeStatus(status) {
      this.valueStatus = status
      if (status === 'Semua Status') {
        let temp = {
          ...this.$route.query,
        }
        delete temp.status
        this.$router.push({
          query: { ...temp },
        })
      } else {
        this.$router.push({
          query: { ...this.$route.query, status },
        })
      }
    },
    onChangeChannel(sales_channel_id) {
      this.valueChannel = sales_channel_id
      if (sales_channel_id === 'all') {
        let temp = {
          ...this.$route.query,
        }
        delete temp.sales_channel_id
        this.$router.push({
          query: { ...temp },
        })
      } else {
        this.$router.push({
          query: { ...this.$route.query, sales_channel_id },
        })
      }
    },
    onChangeCountry(country_id) {
      this.valueCountry = country_id
      if (country_id === 'all') {
        let temp = {
          ...this.$route.query,
        }
        delete temp.country_id
        this.$router.push({
          query: { ...temp },
        })
      } else {
        this.$router.push({
          query: { ...this.$route.query, country_id },
        })
      }
    },
    onSearch(val) {
      this.valueSearch = val
      if (val === '') {
        let temp = {
          ...this.$route.query,
        }
        delete temp.keyword
        this.$router.push({
          query: { ...temp },
        })
      } else {
        this.$router.push({
          query: { ...this.$route.query, keyword: val },
        })
      }
    },
    onChangeSort(sort_by) {
      this.sortBy = sort_by
      this.$router.push({
        query: { ...this.$route.query, sort_by }, // page: value, limit: this.size
      })
    },
    onChangePage(page) {
      this.defaultPage = page
      this.$router.push({
        query: { ...this.$route.query, page },
      })
    },
    onChangeSearch(e) {
      this.valueSearch = e.target.value
    },
    openModal(data) {
      this.modalApproveConfirmation = !this.modalApproveConfirmation
      this.selectedData = data
    },
    openModalApproveConfirmation(id) {
      this.modalApproveConfirmation = !this.modalApproveConfirmation
      this.selectedData = id
    },
    onSuccessApprove() {
      this.modalApproveConfirmation = false
      this.selectedData = null
      this.$router.go()
    },
    openModalAddWarehouse(data) {
      this.showModalAddWarehouseChannel = !this.showModalAddWarehouseChannel
      this.selectedData = data
    },
    actionModalAddWarehouseChannel(id) {
      this.showModalAddWarehouseChannel = !this.showModalAddWarehouseChannel
      this.selectedData = id
    },
    onSuccessAddWarehouse() {
      this.showModalAddWarehouseChannel = false
      this.selectedData = null
      this.$router.go()
    },
    onShowDrawerDetail(item) {
      this.viewDetail = true
      this.dataDrawerDetail = item
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-search:not(:hover) {
  background-color: #2196f3;
  color: #fff;
}

.st-connect::before,
.st-disconnected::before {
  content: '';
  display: inline-block;
  margin-right: 0.5rem;
  vertical-align: middle;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #ff477e;
  user-select: none;
}

.st-connect::before {
  background: #07beb8;
}

.btn-edit:not(:hover) {
  background: #e3f2fd !important;
  border-color: #e3f2fd !important;
  color: #2196f3 !important;
}
.btn-del:not(:hover) {
  background: #ffebf1 !important;
  border-color: #ffebf1 !important;
  color: #ff477e !important;
}
.btn-url:not(:hover) {
  background: #ebfefe !important;
  border-color: #ebfefe !important;
  color: #07beb8 !important;
}

.add__button {
  color: #fff !important;
  background: #ff0854 !important;
}

.store-name {
  color: var(--kit-color-primary);
  cursor: pointer;
}
</style>

<style lang="scss">
.select-antd-default.ant-select-lg .ant-select-selection__rendered {
  line-height: 38px !important;
}
</style>
